import {useState, useEffect} from 'react'
import '../CSS/ContactUs.css'
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const ContactUs = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [phone, setPhone] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect(() => {
      return () => {
        setIsFormSubmitted(false);
      };
    }, []);
  
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      if (!firstName.trim() || !lastName.trim() || !email.trim() || !message.trim() || !phone.trim()) {
        toast.error('Please fill out all fields');
        return;
      }
  
      const templateParams = {
        first: firstName,
        last: lastName,
        message: message,
        email: email,
        phone: phone
      };
  
      try {
        const res = await emailjs.send(
          'service_oufufqe',
          'template_11g1wh4',
          templateParams,
          'SWfZGsmmT_kzjTWiN'
        );
        
        if (res.status === 200) {
          toast.success('Email sent successfully!');
          setFirstName('');
          setLastName('');
          setEmail('');
          setMessage('');
          setPhone('');
          setIsFormSubmitted(true);
        } else {
          throw new Error(`EmailJS returned status ${res.status}`);
        }
      } catch (err: any) {
        toast.error(`Error sending email: ${err.message}`);
      }
    };
  
    return (
      <div className='contactUsWrapper'>
        <ToastContainer />
        {isFormSubmitted ? (
          <div className='messageReceived'>
            <h1>Thank you for your message! We will get back to you as soon as possible! Please double check your spam folder in case it's sent there. We look forward to working with you!</h1>
          </div>
        ) : (
          <section className='contactUsContent'>
            <h1 className='message'>Please fill out the form below to request a quote or click the call button above!</h1>
            <form className='contactForm' onSubmit={handleSubmit}>
              <label htmlFor='firstName'>First Name:</label>
              <input type='text' id='firstName' name='firstName' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              <label htmlFor='lastName'>Last Name:</label>
              <input type='text' id='lastName' name='lastName' value={lastName} onChange={(e) => setLastName(e.target.value)} />
              <label htmlFor='email'>Email:</label>
              <input type='email' id='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
              <label htmlFor='phone'>Phone Number:</label>
              <input type='tel' id='phone' name='phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
              <label htmlFor='message'>Message:</label>
              <textarea id='message' name='message' value={message} onChange={(e) => setMessage(e.target.value)} />
              <button type='submit'>Submit</button>
            </form>
          </section>
        )}
      </div>
  )
}