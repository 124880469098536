import {useState} from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo2 from '../Images/porterLogo3.png'
import '../CSS/Header.css'
import { Phone, Menu, XIcon } from 'lucide-react'
type CallablePhoneNumberProps = {
  phone: string;
  children: string
}
const CallablePhoneNumber: React.FC<CallablePhoneNumberProps> = ({ phone, children }) => {
  return <a className='phone' href={`tel:${phone}`}>{children}</a>;
};
export const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className='headerWrapper'>
      <div className='imageWrapper'>
        <Link to='/' className='logoLink'>
          <img src={logo2} alt='logo' className='logo' />
        </Link>
      </div>
      <div className='linkWrapper'>
        <Phone className='phoneIcon'size={'15px'} color={'turquoise'} />
        <CallablePhoneNumber 
          phone="+1(801)-458-8855" 
          children="Call for a Free Estimate"
          aria-label="Call +1(801)4588855 for a free estimate"
        />        
        {/* Desktop links */}
        <div className='desktopLinks'>
          <NavLink to='/'  className='navLink'>Home</NavLink>
          <NavLink to='/about'  className='navLink'>About</NavLink>
          <NavLink to='/flooring'  className='navLink'>Flooring</NavLink>
          <NavLink to='/contact' className='navLink'>Contact Us</NavLink>
        </div>

        {/* Mobile dropdown trigger */}
        <button className={`mobileDropdownTrigger ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
          {isDropdownOpen ? <XIcon className='menu' size={'30px'} color={'turquoise'} /> : <Menu className='menu' size={'30px'} color={'turquoise'} />}
        </button>

        {/* Mobile dropdown */}
        <div className={`mobileDropdown ${isDropdownOpen ? 'open' : ''}`}>
          <NavLink to='/' onClick={toggleDropdown} className='navLink'>Home</NavLink>
          <NavLink to='/about' onClick={toggleDropdown} className='navLink'>About</NavLink>
          <NavLink to='/flooring' onClick={toggleDropdown} className='navLink'>Flooring</NavLink>
          <NavLink to='/contact'  onClick={toggleDropdown} className='navLink'>Contact Us</NavLink>
        </div>
      </div>
    </div>
  )
}
