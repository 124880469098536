import React from 'react'
import '../CSS/Home.css'
import { Link } from 'react-router-dom'

export const Home: React.FC = () => {
  return (
    <div className='homeWrapper'>
      <div className='initialContent'>
        <div className='contextWrapper'>
          <h1>Welcome to Porter's</h1>
          <h2>Flooring & Remodeling</h2>
        </div>
        <div className='buttonSection'>
            <Link to='/about' className='homeButton'>Learn About Us!</Link>
            <Link to='/flooring' className='flooringButton'>Flooring options & reviews!</Link>
        </div>
      </div>
    </div>
  )
}