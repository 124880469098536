import '../CSS/Footer.css';
export const Footer = () => {
  return (
    <div className='footerWrapper'>
      <footer className='footer'>
        <p>© 2024 Porter's Flooring. All Rights Reserved.</p>
      </footer>
    </div>
  )
}
