import React, { useState } from 'react'
import '../CSS/Flooring.css'
import  {LpvImageSlider} from './LpvImageSlider';
import { SelfLevelingSlider } from './SelfLevelingSlider';
import { WoodImageSlider } from './WoodImageSlider';
type iframeProps = {
    src: string;
    frameBorder: string;
    width: string;
    height: string;
  }

  type ModalType = string | null;

export const Flooring: React.FC<iframeProps> = ({src, frameBorder, width, height}) => {
  const [activeModal, setActiveModal] = useState<ModalType | null>(null);
  const handleModalToggle = (modalType: ModalType) => {
    setActiveModal(activeModal === modalType ? null : modalType)
  }

  

  const renderModal = () => {
    switch (activeModal) {
      case 'lpv':
        return <LpvImageSlider onClose={() => setActiveModal(null)} />
      case 'selfLeveling':
        return <SelfLevelingSlider onClose={() => setActiveModal(null)} />
      case 'wood':
        return <WoodImageSlider onClose={() => setActiveModal(null)} />
      default:
        return null
    }
  }


  return (
    <div className='flooringWrapper'>
        <div className='flooringContent'>
            <h1>See our flooring options and previous work!</h1>
            <div className='lpvContainer'>
              <button className='lpvModalButton' onClick={() => handleModalToggle('lpv')}>Previous LPV Flooring</button>
              <button className='selfLevelingModalButton' onClick={() => handleModalToggle('selfLeveling')}>Previous Self Leveling Flooring</button>
              <button className='woodModalButton' onClick={() => handleModalToggle('wood')}>Previous Wood Flooring</button>
            <h2>We also sell LVP if you would like to purchase and install yourself!</h2>
            <a className='sellLvp'href='https://www.oceansidewaterproofflooring.com/collection' target='_blank'>Checkout our LVP Here</a>
            </div>
            </div>
            <h2>See what people are saying about our installations!</h2>
            <h3>Want to leave us a review? Click the link below!</h3>
            <a className='leaveReview'href=' https://search.google.com/local/writereview?placeid=ChIJwwQdlk6PUocRfiFUmqdT5cU&source=g.page.m.ia._&laa=nmx-review-solicitation-ia2'>Leave us a Review!</a>
            <div className='reviewSection'>
            <iframe title='googleReviews' src={src} frameBorder={frameBorder} width={width} height={height} aria-label='google reviews'></iframe>
            </div>
            {activeModal && (
        <div className='modalOverlay'>
          {renderModal()}
        </div>
      )}
    </div>
  )
}
