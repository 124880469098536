import React, { useEffect, useState, useCallback, useRef } from 'react';
import '../CSS/LpvImageSlider.css'; 
import { LvpImage } from '../Utility/Types';
import floors from '../Utility/floors.json'; 
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface LvpImageSliderProps {
    onClose: () => void;
}

export const LpvImageSlider: React.FC<LvpImageSliderProps> = ({ onClose }) => {
    const [images, setImages] = useState<LvpImage[]>([]);

    useEffect(() => {
        setImages(floors.LvpImages);
    }, []);
   

    return (
        <div className='imageSliderWrapper'>
            <div className='imageContainer'>
            <div className='closeButtonContainer'>
                <button className='closeButton' onClick={onClose}>X</button>
            </div>
            <h1>LVP Flooring</h1>
            <div className='carouselContainer'>
                <Carousel 
                 showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000}
                 showArrows={true}
                >
            {images.map((lvpImages, index) => (   
                    <img aria-label={`View Image ${index}`}key={index} src={lvpImages.src} alt='lpv flooring' className='image' />
                
            ))}
                </Carousel>

            </div>

            </div>
        </div>
    );
};
