import React, { useEffect, useState, useRef, useCallback } from 'react';
import '../CSS/WoodImageSlider.css'; 
import { WoodFlooringImage} from '../Utility/Types';
import floors from '../Utility/floors.json'; 
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
const StyledCarousel = styled(Carousel)`
  ul.thumbs.animated {
    display: none;
  }
`;

interface WoodImageSliderProps {
    onClose: () => void;
}

export const WoodImageSlider: React.FC<WoodImageSliderProps> = ({ onClose }) => {
    const [images, setImages] = useState<WoodFlooringImage[]>([]);
    
    useEffect(() => {
        setImages(floors.WoodFlooringImages);
    }, []);

    return (
        <div className='imageSliderWrapper'>
            <div className='imageContainer'>
            <div className='closeButtonContainer'>
                <button className='closeButton' onClick={onClose}>X</button>
            </div>
            <h1>Wood Flooring</h1>
            <div className='woodCarouselContainer'>
            <StyledCarousel 
                 showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000}
                 showArrows={true}
                >
            {images.map((woodFlooringImages, index) => (
                    <img aria-label={`View Image ${index}`}key={index} src={woodFlooringImages.src} alt='wood flooring' className='image' />
            ))}
            </StyledCarousel>
            </div>
            </div>
        </div>
    );
};
