import '../CSS/About.css'
import { Phone } from 'lucide-react'

const backgroundMountains = require('../Images/mountains.jpg')
type CallablePhoneNumberProps = {
    phone: string;
    children: string
}

const CallablePhoneNumber: React.FC<CallablePhoneNumberProps> = ({ phone, children }) => {
  return <a href={`tel:${phone}`}>{children}</a>;
};



export const About = () => {


  return (
    <div className='aboutWrapper'>
      <img src={backgroundMountains} alt='background' className='backgroundVideo'/>
        <section className='aboutContent'>
            <h1>About Us</h1>
            <p className='aboutText'>Porter's Flooring & Remodeling is a family owned and operated business that has been serving the greater Salt Lake area for over 3 years. We specialize in flooring installation, remodeling, and home improvement. We are dedicated to providing the best quality work and customer service to our clients. Our team of professionals are experienced and knowledgeable in all aspects of flooring and remodeling and have over 10 years of experience. We take pride in our work and strive to exceed our clients' expectations. We are committed to providing the best service and products to our clients at an affordable price. We are fully licensed and insured, and we guarantee our work. Contact us today for a free estimate on your next project!</p>
            <span><Phone size={'30px'} color={'turquoise'}/></span>
            <CallablePhoneNumber phone="+8014588855">Call for a Free Estimate</CallablePhoneNumber><br/>
            <p>We also sell LVP if you would like to purchase and install yourself!</p><a href='https://www.oceansidewaterproofflooring.com/collection' target='_blank'>Checkout our LVP Here</a>
        </section>
    </div>
  )
}
