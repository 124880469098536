import React, { useEffect, useState, useRef, useCallback } from 'react';
import '../CSS/SelfLevelingSlider.css'; 
import { SelfLevelingImage } from '../Utility/Types';
import floors from '../Utility/floors.json'; 
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface SelfLevelingSliderProps {
    onClose: () => void;
}

export const SelfLevelingSlider: React.FC<SelfLevelingSliderProps> = ({ onClose }) => {
    const [images, setImages] = useState<SelfLevelingImage[]>([]);
   
    useEffect(() => {
        setImages(floors.SelfLevelingImages);
    }, []);

    return (
        <div className='imageSliderWrapper'>
            <div className='selfLevelingImageContainer'>
            <div className='closeButtonContainer'>
                <button className='closeButton' onClick={onClose}>X</button>
            </div>
            <h1>Self Leveling Flooring</h1>
            <div className='carouselContainer'>
            <Carousel 
                 showThumbs={false} infiniteLoop={true} autoPlay={true} interval={3000}
                 showArrows={true}
                >
            {images.map((selfLevelingImages, index) => (
                    <img aria-label={`View Image ${index}`}key={index} src={selfLevelingImages.src} alt='self leveling flooring' className='selfLevelingImage' />
                
            ))}
            </Carousel>
            </div>
            </div>
        </div>
    );
};
