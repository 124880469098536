import React from 'react';
import '../CSS/App.css';
import { Route, Routes } from 'react-router-dom';
import { Home } from './Home';
import { About } from './About';
import { Header } from './Header';
import { Footer } from './Footer';
import { Flooring } from './Flooring';
import { ContactUs } from './ContactUs';

export const App = () => {
  
  function NotFound() {
    return <div>
        <h1>404: Page Not Found.</h1>
        <h2>Make sure you typed in the write url endpoint.</h2>
        <h3>Click on any of the options or try again.</h3>
        </div>
        
  }

  return (
    <div className="App">
      <Header />
      <header className="App-header">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/flooring" element={<Flooring src='https://widgets.sociablekit.com/google-reviews/iframe/25450289'
    frameBorder='0'
    width='100%'
    height='1000px'/>}/>
          <Route path="/contact" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </header>
      <Footer />
    </div>
  );
}
